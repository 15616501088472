<template>
    <div>
        <Loader v-if="loading"></Loader>
        <div v-else>
            <CRow v-if="quest != null">
                <CCol lg="12">
                    <CCard>
                        <CCardHeader class="header">
                            <h3>{{ quest.title }}</h3>
                        </CCardHeader>
                        <CCardBody>
                            <div class="quest-content">
                                <p class="quest-description">{{ quest.description }}</p>
                                <span><strong>Necesidad del hardware:</strong>
                                    <CIcon class="hardware-icon" :name="(quest.need_device == 1) ? 'cil-check' : 'cil-x'" />
                                </span>
                                <span><strong>Perioricidad (días): </strong>{{ quest.periocity }}</span>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol lg="12">
                    <CCard class="answers-and-questions">
                        <CCardHeader class="header">
                            <span>Preguntas y respuestas</span>
                        </CCardHeader>
                        <CCardBody>
                            <div v-if="quest.questions != ''">
                                <fieldset class="p-2 m-2" v-for="(item, index) in quest.questions" :key="index">
                                    <div class="question-container">
                                        <span><strong>{{ item.body }}</strong></span>
                                        <div class="answer-container">
                                            <div v-if="item.type == 'EVA'">
                                                <span>La respuesta a esta pregunta es una escala EVA</span> 
                                                <ComponentEVA />
                                            </div>
                                            <fieldset v-else class="p-2 m-2" v-for="(answer, index) in item.answers" :key="index">
                                                <span class="image-response" v-if="item.type == 'photo'">
                                                    La respuesta a esta pregunta es una imagen
                                                </span>
                                                <span v-else>- {{ answer.answer }}</span>
                                            </fieldset>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div v-else>
                                No hay preguntas ni respuestas para este cuestionario.
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    </div>
</template>
  
<script>
import { getQuestDataById } from '@/services/quests';
import ComponentEVA from '@/components/ComponentEVA.vue';
import Loader from '@/components/Loader.vue';
export default {
    name: "showQuest",
    components: {ComponentEVA, Loader},
    data() {
        return {
            loading: true,
            quest: null,
        }
    },
    created() {
        this.receiveData();
    },
    methods: {
        receiveData() {
            this.getQuest();
        },
        /**
        * Función en la que se obtiene la información del cuestionario y que también
        * llama el metodo receiveData para cargarlos.
        * 
        * @returns {object[]} El cuestionario obtenido.
        */
        getQuest() {
            getQuestDataById(this.$route.params.id)
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
                .then((response) => { this.quest = response; this.loading = false;});
        },
    }
};
</script>
<style scoped>
.question-container {
    margin: 0px 10px;
}

.answer-container {
    margin: 8px 20px;
}

.question-container>span {
    font-size: 20px;
}

.answer-container>span {
    font-size: 16px;
}

.quest-content span {
    display: block;
    font-size: 16px;
}

.image-response {
    font-size: 16px;
    width: 300px;
    display: table-cell;
    border: 1px solid;
    border-color: #d8dbe0;
    text-align: center;
    vertical-align: middle;
    height: 180px;
    border-radius: 2px;
}

.quest-description {
    font-size: 15px;
}

.hardware-icon {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-left: 5px;
}

@media (max-width: 639px) {
    .card-header h2 {
        font-size: 20px;
    }

    .answers-and-questions .card-body {
        padding: 10px 0;
    }

    .question-container {
        margin: 0 !important;
    }

    .answer-container {
        margin: 8px 0 !important;
    }

    .question-container>span>strong {
        font-size: 18px;
    }

    .answer-container>span {
        padding: 10px 5px;
        font-size: 14px
    }

    .quest-content span {
        font-size: 14px;
    }
    .image-response{
        font-size: 14px;
        width: auto;
        padding: 0 5px;
    }
}
</style>
