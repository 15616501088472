import config from "@/config";

import { get, del, post, patch } from "@/requests"

export async function getQuestsData() {
    return get(`${config.BACK_IP}/quest`)
}

export async function getQuestsDataBySession(patientId) {
    return get(`${config.BACK_IP}/patient/${patientId}/session`)
}

export async function getQuestDataById(id) {
    return get(`${config.BACK_IP}/quest/${id}`)
}

export async function getQuestionsAndAnswers(patientId, sessionId) {
    return get(`${config.BACK_IP}/patient/${patientId}/session/${sessionId}`)
}

export async function createNewQuest(newQuest) {
    return post(`${config.BACK_IP}/quest`, newQuest)
}

export async function updateQuest(newQuest, id) {
    return patch(`${config.BACK_IP}/quest/${id}`, newQuest)
}

export function deleteQuest(id) {
    return del(`${config.BACK_IP}/quest/${id}`)
}